<template>
  <v-data-table
      class="elevation-4"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      :items-per-page="5"
      :headers="headers"
      :items="value"
  >
    <template v-slot:top>
      <div class="pa-1 subtitle-1 text-center">
        Relatório SCM
      </div>
    </template>
    <template v-slot:item.ct="{item}">
      {{ item | clientTypeDescription }}
    </template>
    <template v-slot:item.at="{item}">
      {{ item | attendanceTypeDescription }}
    </template>
    <template v-slot:item.et="{item}">
      {{ item | environmentTypeDescription }}
    </template>
    <template v-slot:item.pt="{item}">
      {{ item | productTypeDescription }}
    </template>
    <template v-slot:item.tt="{item}">
      {{ item | technologyTypeDescription }}
    </template>
    <template v-slot:item.a="{item}">
      <v-text-field
          outlined
          filled
          v-model.number="item.a"
          type="number"
          min="0"
          :rules="rulesAccessQuantity"
      />
    </template>
  </v-data-table>
</template>

<script>
import {attendanceTypes, clientTypes, environmentTypes, productTypes, technologyTypes} from '@/lib/diciTypes';
import {rulesAccessQuantity} from '@/lib/validation';

const getTypeDescription = (array, item, value) => {
  const items = array.filter(v => item[value] === v.value)
  if (items.length === 1) {
    return items[0].name
  }
  return 'Não informado';
}

export default {
  props: {
    value: Array
  },
  filters: {
    clientTypeDescription: function (item) {
      return getTypeDescription(clientTypes, item, 'ct');
    },
    attendanceTypeDescription: function (item) {
      return getTypeDescription(attendanceTypes, item, 'at');
    },
    environmentTypeDescription: function (item) {
      return getTypeDescription(environmentTypes, item, 'et');
    },
    productTypeDescription: function (item) {
      return getTypeDescription(productTypes, item, 'pt');
    },
    technologyTypeDescription: function (item) {
      return getTypeDescription(technologyTypes, item, 'tt');
    }
  },
  name: 'SCMReportingClient',
  data() {
    return {
      rulesAccessQuantity: rulesAccessQuantity,
      technologyTypes: technologyTypes,
      productTypes: productTypes,
      environmentTypes: environmentTypes,
      attendanceTypes: attendanceTypes,
      clientTypes: clientTypes,
      headers: [
        {text: 'Município', value: 'dc'},
        {text: 'Tipo de cliente', value: 'ct'},
        {text: 'Tipo de atendimento', value: 'at'},
        {text: 'Tipo de meio', value: 'et'},
        {text: 'Tipo de produto', value: 'pt'},
        {text: 'Tipo de tecnologia', value: 'tt'},
        {text: 'Quantidade de acessos', value: 'a'}
      ]
    }
  }
}
</script>
