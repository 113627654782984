<template>
  <div class="fill-height">
    <app-nav-bar title-bar="Configurações" action-menu :back-route="goToHome"/>
    <v-main class="fill-height">
      <v-container fluid>
        <v-row>
          <v-col :key="i" v-for="(item, i) in items" cols="12" sm="6" md="6" lg="4">
            <v-card class="fill-height">
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="6">
                      {{ item.text }}
                    </v-col>
                    <v-col class="text-center" cols="6">
                      <v-avatar size="100" tile>
                        <img alt="icon" :src="require(`../assets/menu/settings/${item.img}`)"/>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-btn color="primary" large text @click="item.click">
                  {{ item.button }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToEmailConfirmationTemplate, goToEmailWarningTemplate, goToHome} from '@/lib/redirects';

export default {
  name: 'Settings',
  components: {AppNavBar},
  data() {
    return {
      goToHome: goToHome,
      headers: [
        {text: '', value: 'description'},
        {text: 'Ações', value: 'action'}
      ],
      items: [
        {
          title: 'Modelo de aviso',
          text: 'Modelo de email para lembrar o cliente no primeiro dia do mês a declarar as informações necessárias para a produção do relatório.',
          button: 'Abrir modelo',
          img: 'mail.svg',
          click: () => {
            goToEmailWarningTemplate();
          }
        },
        {
          title: 'Modelo de confirmação de envio',
          text: 'Modelo de email para o cliente ser notificado sobre o envio do relatório da sua empresa para a Anatel.',
          button: 'Abrir modelo',
          img: 'email.svg',
          click: () => {
            goToEmailConfirmationTemplate();
          }
        }
      ]
    }
  },
  methods: {
    open(item) {
      console.log(item);
    }
  }
}
</script>
