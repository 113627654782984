<template>
  <loader :loading="loading">
    <v-container slot="content">
      <v-row>
        <v-col v-if="variables.length" cols="3">
          <div class="font-weight-bold text-uppercase">Variáveis</div>
          <div :key="index" v-for="(v, index) in variables">{{ v }}</div>
        </v-col>
        <v-col class="text-right" cols="8">
          <v-btn class="ml-2 mr-2" color="primary" @click="loadSavedModelClick">
            <v-icon class="mr-2">mdi-cloud-download</v-icon>
            Carregar modelo salvo
          </v-btn>
          <v-btn class="ml-2 mr-2" color="primary" @click="saveModel">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-col>
        <v-col cols="12">
          <EmailEditor ref="emailEditor" locale="pt-br" v-on:load="editorLoaded"/>
        </v-col>
      </v-row>
    </v-container>
  </loader>
</template>

<script>
import {EmailEditor} from 'vue-email-editor'
import Loader from "@/components/Loader";
import {getEmailTemplate, putEmailTemplate} from '@/lib/backend/emailTemplate';
import {mapActions} from 'vuex';

const SAVED = 'Salvo';
const LOADED = 'Carregado';
const CONFIRM = 'Confirmar?';
const CONFIRM_LOAD = 'Todas as alterações não salvas serão perdidas.';
const CONFIRM_SAVE = 'As alterações substituirão os dados que estão salvos.';
const EMPTY_TEMPLATE = 'Template vazio.';

export default {
  props: {
    nameConfig: String,
    variables: Array
  },
  name: 'EmailTemplate',
  components: {
    Loader,
    EmailEditor
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    editorLoaded() {
      this.loadSavedModel();
    },
    async loadSavedModel() {
      this.loading = true;
      const response = await getEmailTemplate(this.nameConfig);
      if (response) {
        if (response.status === 200) {
          if (response.data.data) {
            const design = JSON.parse(response.data.data).design;
            this.$refs.emailEditor.editor.loadDesign(design);
          }
        } else {
          this.showAlert({message: response.message});
        }
        this.loading = false;
      }
    },
    saveModel() {
      this.showConfirmation({
        title: CONFIRM,
        text: CONFIRM_SAVE,
        action: () => {
          this.loading = true;
          this.$refs.emailEditor.editor.exportHtml(
              async (data) => {
                if (data) {
                  const response = await putEmailTemplate(this.nameConfig, {
                    design: data.design,
                    html: data.html
                  });
                  if (response) {
                    if (response.status === 200) {
                      this.showAlert({message: SAVED, timeout: 1000, color: 'success'});
                    } else {
                      this.showAlert({message: response.message});
                    }
                  }
                } else {
                  this.showAlert({message: EMPTY_TEMPLATE});
                }
                this.loading = false;
              }
          )
        }
      });
    },
    loadSavedModelClick() {
      this.showConfirmation({
        title: CONFIRM,
        text: CONFIRM_LOAD,
        action: () => {
          this.loadSavedModel();
          this.showAlert({message: LOADED, timeout: 1000, color: 'success'});
        }
      });
    }
  }
}
</script>

<style>
iframe {
  min-height: 80vh !important;
}
</style>
