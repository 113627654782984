<template>
  <v-card tile flat :loading="loading" :disabled="loading">
    <app-nav-bar title-bar="Empresas" action-menu :back-route="goToHome"/>

    <dialog-form v-model="dialogEdit" :dialog-label="dialogLabel" :dialog-loading="loadingEdit"
                 @ok="createEditCompany">
      <v-container slot="fields">
        <v-row>
          <v-col cols="12">
            <v-text-field
                ref="companyName"
                v-model="companyNameUpper"
                label="Nome"
                :rules="companyNameRules"
                :maxLength="100"
                :counter="100"
                validate-on-blur
                required
            />
            <v-text-field
                ref="cnpj"
                type="tel"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                label="CNPJ"
                :rules="cnpjNameRules"
                validate-on-blur
                required
            />
            <v-text-field
                ref="email"
                type="email"
                v-model="emailLower"
                label="E-mail"
                :rules="emailRules"
                :maxLength="maxEmailLength"
                :counter="maxEmailLength"
                clearable
                validate-on-blur
                required
            />
          </v-col>
        </v-row>
      </v-container>
    </dialog-form>

    <v-dialog v-model="dialogReportLink" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Copie ou abra o link</span>
        </v-card-title>

        <v-card-text>
          <router-link :to="{ name: 'reporting', query: { token: token }}" target="_blank">
            LINK
            <v-icon x-small color="primary">mdi-open-in-new</v-icon>
          </router-link>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogReportLink = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTemplate"
              persistent
              fullscreen
              hide-overlay
              scrollable
              transition="dialog-bottom-transition">
      <v-card tile flat :loading="loadingTemplate" :disabled="loadingTemplate">
        <v-toolbar flat dark color="primary" max-height="64">
          <v-btn icon dark @click="dialogTemplate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Modelo de Relatório</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn dark text @click="saveTemplate">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-form ref="form">
            <v-container v-if="dialogTemplate" fluid>
              <v-row class="subtitle-1 font-weight-bold" v-if="companyTemplate">
                <v-col>{{ `${companyTemplate.name} - ${companyTemplate.cnpj.cnpjMask()}` }}</v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <s-c-m-reporting v-if="dialogTemplate" v-model="data.scm"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="9" lg="8">
            <v-data-table
                :headers="headers"
                :items="companies"
                :search="search"
                sort-by="name"
            >
              <template v-slot:top>
                <v-text-field
                    label="Procurar..."
                    color="primary"
                    v-model="search"
                    clearable
                    append-icon="mdi-magnify"
                />
              </template>
              <template v-slot:item.cnpj="{item}">
                {{ item.cnpj.cnpjMask() }}
              </template>
              <template v-slot:item.enabled="{item}">
                <v-switch
                    :disabled="loading"
                    v-model="item.enabled"
                    @change="changeEnabled(item)"
                />
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" text v-bind="attrs" v-on="on">mdi-menu</v-icon>
                  </template>
                  <v-list>
                    <v-list-item link @click="openReporting(item)">
                      <v-icon class="mr-2">mdi-open-in-new</v-icon>
                      <v-list-item-title>Criar link de relatório</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openTemplate(item)">
                      <v-icon class="mr-2">mdi-playlist-edit</v-icon>
                      <v-list-item-title>Editar modelo de relatório</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openDialog(item)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      <v-list-item-title>Editar empresa</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="removeCompany(item)">
                      <v-icon class="mr-2">mdi-delete</v-icon>
                      <v-list-item-title>Remover empresa</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-btn class="mr-5 mb-5" color="primary" fab fixed bottom right @click="openDialog(null)">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import {goToHome} from '@/lib/redirects';
import AppNavBar from '@/components/AppNavBar';
import {
  deleteCompany,
  getCompany,
  postCompany,
  putAccessCompany,
  putCompany,
  postCreateReportToken
} from '@/lib/backend/company';
import DialogForm from '@/components/DialogForm';
import {MAX_EMAIL_LENGTH, validateReportFields, validCnpj, validEmail} from '@/lib/validation';
import {mapActions} from 'vuex';
import {mask} from 'vue-the-mask';
import SCMReporting from '@/views/reporting/SCMReporting';
import {postTemplate, putTemplate} from '@/lib/backend/template';
import VERSION from '@/lib/version';
import {getEmptyData} from '@/lib/diciTypes';

const FIELD_REQUIRED = 'Este campo é obrigatório';
const INVALID_CNPJ = 'CNPJ inválido';
const INVALID_EMAIL = 'E-mail inválido';
const SAVED = 'Salvo';
const CONFIRM = 'Confirmar?';
const EDIT_COMPANY = 'Editar empresa';
const CREATE_COMPANY = 'Cadastrar empresa';
const ALREADY_EXISTS_CNPJ = 'CNPJ atribuído à outra empresa';
const VERIFY_FIELDS = 'Todos os campos precisam ser válidos antes de serem salvos.';
const customDelete = val => `Excluir ${val}`;

export default {
  name: 'Companies',
  components: {SCMReporting, DialogForm, AppNavBar},
  directives: {mask},
  data() {
    return {
      token: '',
      dialogReportLink: false,
      maxEmailLength: MAX_EMAIL_LENGTH,
      dialogEdit: false,
      dialogTemplate: false,
      companyTemplate: null,
      templateId: 0,
      companyId: 0,
      enabled: false,
      companyName: '',
      cnpj: '',
      email: '',
      phone: '',
      goToHome: goToHome,
      search: '',
      loading: false,
      loadingTemplate: false,
      loadingEdit: false,
      companies: [],
      headers: [
        {text: 'CNPJ', value: 'cnpj', width: '170'},
        {text: 'Nome', value: 'name'},
        {text: 'Ativo', value: 'enabled'},
        {text: 'Ações', value: 'action', sortable: false}
      ],
      companyNameRules: [
        v => !!v || FIELD_REQUIRED
      ],
      cnpjNameRules: [
        (v) => validCnpj(v) || INVALID_CNPJ
      ],
      emailRules: [
        (v) => validEmail(v) || INVALID_EMAIL
      ],
      data: getEmptyData()
    }
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadCompanies() {
      this.loading = true;
      const response = await getCompany();
      if (response) {
        if (response.status === 200) {
          this.companies = response.data;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async createEditCompany() {
      this.loadingEdit = true;
      const method = this.companyId === 0 ? postCompany : putCompany;
      const response = await method({
        id: this.companyId,
        cnpj: this.cnpj.onlyNumbers(),
        name: this.companyName.toUpperCase(),
        email: this.email.toLowerCase(),
        enabled: this.enabled
      });
      if (response) {
        if (response.status === 200) {
          if (this.companyId) {
            this.companies.replace(response.data);
          } else {
            this.companies.push(response.data);
          }
          this.showAlert({message: SAVED, timeout: 1000, color: 'success'});
        } else if (response.status === 409) {
          this.showAlert({message: ALREADY_EXISTS_CNPJ});
          this.loadingEdit = false;
          return;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loadingEdit = false;
      this.dialogEdit = false;
    },
    openDialog(company) {
      if (company) {
        this.companyId = company.id;
        this.cnpj = company.cnpj ? company.cnpj.cnpjMask() : '';
        this.companyName = company.name;
        this.email = company.email;
        this.enabled = company.enabled;
      } else {
        this.companyId = 0;
        this.cnpj = '';
        this.email = '';
        this.companyName = '';
        this.enabled = true;
      }
      this.dialogEdit = true;
      const ctx = this;
      setTimeout(() => {
        ctx.$refs.companyName.focus();
      }, 100);
    },
    removeCompany(company) {
      this.showConfirmation({
        title: CONFIRM,
        text: customDelete(company.name),
        action: async () => {
          this.loading = true;
          const response = await deleteCompany(company);
          if (response) {
            if (response.status === 200) {
              this.companies.remove(company)
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.loading = false;
        }
      });
    },
    async changeEnabled(item) {
      this.loading = true;
      const response = await putAccessCompany(item.id, {
        enabled: item.enabled
      });
      if (response) {
        if (response.status === 200) {
          this.showAlert({message: SAVED, timeout: 1000, color: 'success'});
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async openReporting(item) {
      this.loading = true;
      const response = await postCreateReportToken(item.id);
      if (response) {
        if (response.status === 200) {
          this.token = response.data.token;
          this.dialogReportLink = true;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async openTemplate(item) {
      this.loading = true;
      this.data = getEmptyData();
      const response = await postTemplate(item.id);
      if (response) {
        if (response.status === 200) {
          if (response.data.version === VERSION && response.data.data) {
            this.data = JSON.parse(response.data.data);
          }
          this.templateId = response.data.id;
          this.companyTemplate = item;
          this.dialogTemplate = true;
        } else {
          this.showAlert({message: response.message})
        }
      }
      this.loading = false;
    },
    async saveTemplate() {
      this.loadingTemplate = true;
      if (this.$refs.form.validate() && this.templateId) {
        const message = validateReportFields(this.data);
        if (!message) {
          const response = await putTemplate(this.templateId, VERSION, this.data);
          if (response) {
            if (response.status === 200) {
              this.showAlert({message: SAVED, timeout: 1000, color: 'success'})
              this.dialogTemplate = false;
            } else {
              this.showAlert({message: response.message})
            }
          }
        } else {
          this.showAlert({message: message})
        }
      } else {
        this.showAlert({message: VERIFY_FIELDS})
      }
      this.loadingTemplate = false;
    }
  },
  computed: {
    companyNameUpper: {
      get: function () {
        if (this.companyName) {
          return this.companyName.toUpperCase();
        }
        return this.companyName;
      },
      set: function (val) {
        if (val) {
          this.companyName = val.toUpperCase();
        }
        this.companyName = val;
      }
    },
    emailLower: {
      get: function () {
        if (this.email) {
          return this.email.toLowerCase();
        }
        return this.email;
      },
      set: function (val) {
        if (val) {
          this.email = val.toLowerCase();
        }
        this.email = val;
      }
    },
    dialogLabel() {
      if (this.companyId) {
        return EDIT_COMPANY;
      } else {
        return CREATE_COMPANY;
      }
    }
  }
}
</script>
