export const months = [
    {
        value: 1,
        name: 'Janeiro'
    },
    {
        value: 2,
        name: 'Fevereiro'
    },
    {
        value: 3,
        name: 'Março'
    },
    {
        value: 4,
        name: 'Abril'
    },
    {
        value: 5,
        name: 'Maio'
    },
    {
        value: 6,
        name: 'Junho'
    },
    {
        value: 7,
        name: 'Julho'
    },
    {
        value: 8,
        name: 'Agosto'
    },
    {
        value: 9,
        name: 'Setembro'
    },
    {
        value: 10,
        name: 'Outubro'
    },
    {
        value: 11,
        name: 'Novembro'
    },
    {
        value: 12,
        name: 'Dezembro'
    }
];

function getMonthsKeyed() {
    const keyed = {};
    months.map(m => {
        keyed[m.value] = m.name;
    })
    return keyed;
}

export const monthsKeyed = getMonthsKeyed();
export const clientTypes = [
    {
        name: 'Pessoa Física',
        value: 'PF'
    },
    {
        name: 'Pessoa Jurídica',
        value: 'PJ'
    },
    {
        name: 'Uso Próprio',
        value: 'UP'
    }
];
export const attendanceTypes = [
    {
        name: 'Urbano',
        value: 'URBANO'
    },
    {
        name: 'Rural',
        value: 'RURAL'
    }
];
export const environmentTypes = [
    {
        name: 'Fibra',
        value: 'fibra'
    },
    {
        name: 'Cabo Coaxial',
        value: 'cabo_coaxial'
    },
    {
        name: 'Cabo Metálico',
        value: 'cabo_metalico'
    },
    {
        name: 'Rádio',
        value: 'radio'
    },
    {
        name: 'Satélite',
        value: 'satelite'
    }
];
export const productTypes = [
    {
        name: 'Internet',
        value: 'internet'
    },
    {
        name: 'Linha dedicada',
        value: 'linha_dedicada'
    },
    {
        name: 'M2M',
        value: 'm2m'
    },
    {
        name: 'Outros',
        value: 'outros'
    }
];
export const technologyTypes = [
    {
        value: 'ADSL1',
        name: 'ADSL'
    },
    {
        value: 'ADSL2',
        name: 'ADSL'
    },
    {
        value: 'AMPS',
        name: 'Celular Analógico'
    },
    {
        value: 'ATM',
        name: 'ATM'
    },
    {
        value: 'Cable Modem',
        name: 'Cable Modem'
    },
    {
        value: 'CDMA IS 95',
        name: '2G CDMA IS 95'
    },
    {
        value: 'CDMA2000',
        name: '2,5G'
    },
    {
        value: 'DTH',
        name: 'TV Via Satélite'
    },
    {
        value: 'EDGE',
        name: '2G'
    },
    {
        value: 'ETHERNET',
        name: 'Ethernet'
    },
    {
        value: 'FR',
        name: 'Frame Relay'
    },
    {
        value: 'FTTB',
        name: 'Fibra no Prédio'
    },
    {
        value: 'FTTH',
        name: 'Fibra em Casa'
    },
    {
        value: 'FWA',
        name: 'FWA'
    },
    {
        value: 'GPRS',
        name: '2G'
    },
    {
        value: 'GSM',
        name: '2G'
    },
    {
        value: 'HDSL',
        name: 'Link Dedicado'
    },
    {
        value: 'HFC',
        name: 'Rede híbrida de fibra e cabo'
    },
    {
        value: 'HSDPA',
        name: '3G'
    },
    {
        value: 'HSPA',
        name: '3G'
    },
    {
        value: 'HSPA+',
        name: '3G+'
    },
    {
        value: 'HSPAP',
        name: '3G'
    },
    {
        value: 'HSUPA',
        name: '3G'
    },
    {
        value: 'LTE',
        name: '4G'
    },
    {
        value: 'MMDS',
        name: 'MMDS'
    },
    {
        value: 'NR',
        name: '5G'
    },
    {
        value: 'OFDMA/TDD',
        name: 'OFDMA'
    },
    {
        value: 'PLC',
        name: 'Via Rede Elétrica'
    },
    {
        value: 'TDMA',
        name: '2G'
    },
    {
        value: 'VDSL',
        name: 'ADSL'
    },
    {
        value: 'VSAT',
        name: 'Internet Via Satélite'
    },
    {
        value: 'WCDMA',
        name: '3G'
    },
    {
        value: 'Wi-Fi',
        name: 'Wi-Fi'
    },
    {
        value: 'WIMAX',
        name: 'WiMAX'
    }
];
export const ufs = [
    {name: 'Acre', value: 'AC'},
    {name: 'Alagoas', value: 'AL'},
    {name: 'Amapá', value: 'AP'},
    {name: 'Amazonas', value: 'AM'},
    {name: 'Bahia', value: 'BA'},
    {name: 'Ceará', value: 'CE'},
    {name: 'Distrito Federal', value: 'DF'},
    {name: 'Espírito Santo', value: 'ES'},
    {name: 'Goiás', value: 'GO'},
    {name: 'Maranhão', value: 'MA'},
    {name: 'Mato Grosso', value: 'MT'},
    {name: 'Mato Grosso do Sul', value: 'MS'},
    {name: 'Minas Gerais', value: 'MG'},
    {name: 'Pará', value: 'PA'},
    {name: 'Paraíba', value: 'PB'},
    {name: 'Paraná', value: 'PR'},
    {name: 'Pernambuco', value: 'PE'},
    {name: 'Piauí', value: 'PI'},
    {name: 'Rio de Janeiro', value: 'RJ'},
    {name: 'Rio Grande do Norte', value: 'RN'},
    {name: 'Rio Grande do Sul', value: 'RS'},
    {name: 'Rondônia', value: 'RO'},
    {name: 'Roraima', value: 'RR'},
    {name: 'Santa Catarina', value: 'SC'},
    {name: 'São Paulo', value: 'SP'},
    {name: 'Sergipe', value: 'SE'},
    {name: 'Tocantins', value: 'TO'}

];
export const getEmptyData = () => {
    return {
        scm: [],
        stfc: [],
        extended_stfc: [],
        tvpa: [],
        smp: []
    }
};
