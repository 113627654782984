var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","items-per-page":5,"headers":_vm.headers,"items":_vm.value},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-1 subtitle-1 text-center"},[_vm._v(" Relatório SCM ")])]},proxy:true},{key:"item.ct",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("clientTypeDescription")(item))+" ")]}},{key:"item.at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("attendanceTypeDescription")(item))+" ")]}},{key:"item.et",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("environmentTypeDescription")(item))+" ")]}},{key:"item.pt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("productTypeDescription")(item))+" ")]}},{key:"item.tt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("technologyTypeDescription")(item))+" ")]}},{key:"item.a",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","filled":"","type":"number","min":"0","rules":_vm.rulesAccessQuantity},model:{value:(item.a),callback:function ($$v) {_vm.$set(item, "a", _vm._n($$v))},expression:"item.a"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }