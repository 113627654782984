import {request, dici} from '@/lib/backend/http';
import admin from '@/lib/backend/admin'

export const getCompany = () => {
    return request(dici().get, admin('/company'));
};

export const postCompany = (company) => {
    return request(dici().post, admin('/company'), company);
};

export const putAccessCompany = (id, access) => {
    return request(dici().put, admin(`/company/${id}/access`), access);
};

export const putCompany = (company) => {
    return request(dici().put, admin(`/company/${company.id}`), company);
};

export const deleteCompany = (company) => {
    return request(dici().delete, admin(`/company/${company.id}`));
};

export const postCreateReportToken = (id) => {
    return request(dici().post, admin(`/company/${id}/create-report-token`));
};
