import {
    goToAccount,
    goToCompanies,
    goToHome, goToReports, goToRoutines, goToSettings
} from '@/lib/redirects';

export const home = () => {
    return {
        title: 'Inicial',
        text: '',
        button: '',
        img: '',
        icon: 'mdi-home',
        click: () => {
            goToHome();
        }
    }
};

export const account = () => {
    return {
        title: 'Conta',
        text: 'Realize alterações de senha, nome e contatos.',
        button: 'Gerenciar sua conta',
        icon: 'mdi-account',
        img: 'user-profile.svg',
        click: () => {
            goToAccount();
        }
    }
};

export const companies = () => {
    return {
        title: 'Empresas',
        text: 'Cadastre, edite e remova as empresas que farão parte dos relatórios.',
        button: 'Gerenciar empresas',
        icon: 'mdi-domain',
        img: 'company.svg',
        click: () => {
            goToCompanies();
        }
    }
};

export const reports = () => {
    return {
        title: 'Relatórios',
        text: 'Envie e gerencie os relatórios enviados para o sistema.',
        button: 'Gerenciar relatórios',
        icon: 'mdi-format-list-bulleted',
        img: 'project.svg',
        click: () => {
            goToReports();
        }
    }
};

export const settings = () => {
    return {
        title: 'Configurações',
        text: 'Gerencie as configurações do sistema.',
        button: 'Abrir configurações',
        icon: 'mdi-cog',
        img: 'settings.svg',
        click: () => {
            goToSettings();
        }
    }
};

export const routines = () => {
    return {
        title: 'Rotinas',
        text: 'Execute tarefas e serviços do sistema',
        button: 'Ir para rotinas',
        icon: 'mdi-play',
        img: 'time-management.svg',
        click: () => {
            goToRoutines();
        }
    }
};
