<template>
  <div class="fill-height">
    <app-nav-bar title-bar="Modelo de confirmação" action-menu :back-route="goToSettings"/>
    <v-main class="fill-height">
      <email-template :name-config="emailTemplates.emailConfirmationTemplate" :variables="variables"/>
    </v-main>
  </div>
</template>

<script>
import EmailTemplate from '@/views/settings/EmailTemplate';
import {goToSettings} from '@/lib/redirects';
import AppNavBar from '@/components/AppNavBar';
import emailTemplates from '@/lib/emailTemplates';

export default {
  name: 'EmailConfirmationTemplate',
  components: {AppNavBar, EmailTemplate},
  data() {
    return {
      goToSettings: goToSettings,
      emailTemplates: emailTemplates,
      variables: [
        '{{NOME}}: Nome da empresa',
        '{{REFERENCIA}}: Mês referência'
      ]
    }
  }
}
</script>
