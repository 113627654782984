<template>
  <v-card flat tile :loading="loading" :disabled="loading">
    <app-nav-bar title-bar="Relatórios" action-menu :back-route="goToHome"/>

    <v-dialog v-model="dialogStatus" persistent max-width="500">
      <v-card :disabled="loadingStatus" :loading="loadingStatus">
        <v-card-title class="title">{{ `Status do relatório ${statusChange.id}` }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="statusChange.status">
            <v-radio :key="i" v-for="(v, i) in reportStatus" :label="v" :value="i"/>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogStatus = false">Voltar</v-btn>
          <v-btn color="primary" text @click="changeStatus">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInfo" max-width="500">
      <v-card>
        <v-card-title class="title">{{ `Informações do relatório ${reportInfo.id}` }}</v-card-title>
        <v-card-text>
          <div>
            <span class="subtitle-1 font-weight-bold">Mensagem: </span>
            <span>{{ reportInfo.message || 'Não informado' }}</span>
          </div>
          <div>
            <span class="subtitle-1 font-weight-bold">Horário: </span>
            <span>
              {{ reportInfo.anatel_stamp ? new Date(reportInfo.anatel_stamp).toLocaleString() : 'Não informado' }}
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogInfo = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
                v-model="selected"
                disable-sort
                :show-select="!!reports.length && status === 0"
                :headers="headers"
                :items="reports"
                :options.sync="options"
                :server-items-length="totalReports"
                :footer-props="{'items-per-page-options': [5, 10, 15, 20, 50]}"
            >
              <template v-slot:top>
                <v-container fluid class="pa-0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-alert v-model="anatelSending" dismissible border="top" colored-border type="info"
                               elevation="2">
                        Os relatórios foram enviados para o serviço de processamento. Este procedimento pode levar
                        alguns
                        minutos.
                        Acompanhe o
                        processo pelos status dos relatórios.
                        Usuário utilizado para o envio: <strong>{{ userNameSend }}</strong>.
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-radio-group v-model="status" row @change="loadReports">
                        <v-radio :key="i" v-for="(v, i) in reportStatus" :label="v" :value="i"/>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn color="primary" block text @click="loadReports">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="pt-10" v-show="selected.length" no-gutters>
                    <v-col class="text-right" cols="12">
                      <v-btn color="primary" @click="confirmSendAnatel">
                        <v-icon class="mr-2">mdi-send</v-icon>
                        Enviar para a Anatel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.created_at="{ item }">
                <div>{{ new Date(item.created_at).toLocaleString() }}</div>
              </template>
              <template v-slot:item.status="{ item }">
                <div>{{ reportStatus[item.status] }}</div>
              </template>
              <template v-slot:item.type="{ item }">
                <div v-if="item.type === 0">CLIENTE</div>
                <div v-else-if="item.type === 1 && item.user_name">{{ item.user_name }}</div>
                <div v-else-if="item.type === 2">SISTEMA</div>
                <div v-else>ANÔNIMO</div>
              </template>
              <template v-slot:item.month="{ item }">
                {{ monthsKeyed[item.month] }}
              </template>
              <template v-slot:item.company_cnpj="{ item }">
                {{ item.company_cnpj }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" text v-bind="attrs" v-on="on">mdi-menu</v-icon>
                  </template>
                  <v-list>
                    <v-list-item link @click="openChangeStatus(item)">
                      <v-icon class="mr-2">mdi-list-status</v-icon>
                      <v-list-item-title>Alterar status</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showInfo(item)">
                      <v-icon class="mr-2">mdi-information</v-icon>
                      <v-list-item-title>Mostrar informações</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="confirmDeleteReport(item)">
                      <v-icon class="mr-2">mdi-delete</v-icon>
                      <v-list-item-title>Remover relatório</v-list-item-title>
                    </v-list-item>
                    <a style="text-decoration: none" target="_blank" :href="getDownloadLink(item)">
                      <v-list-item link>
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-download</v-icon>
                          Baixar arquivos .zip
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-card>
</template>

<script>
import {goToHome} from '@/lib/redirects';
import AppNavBar from '@/components/AppNavBar';
import {deleteReport, getReports, putReportStatus, sendReportsToAnatel} from '@/lib/backend/report';
import {months, monthsKeyed} from '@/lib/diciTypes';
import {mapActions} from 'vuex';

const CONFIRM = 'Confirmar?';
const customDelete = val => `Excluir relatório ${val.id}. Referência: ${monthsKeyed[val.month]}/${val.year}. Empresa: ${val.company_name}.`;
const SEND_ANATEL = 'Os relatórios selecionados serão enviados para a Anatel.';

export default {
  name: 'Reports',
  components: {AppNavBar},
  data() {
    return {
      userNameSend: '',
      dialogInfo: false,
      reportInfo: {
        id: 0,
        anatel_stamp: null,
        message: null
      },
      loadingStatus: false,
      dialogStatus: false,
      anatelSending: false,
      monthsKeyed: monthsKeyed,
      months: months,
      totalReports: 0,
      options: {},
      loading: false,
      goToHome: goToHome,
      status: 0,
      statusChange: {
        id: 0,
        status: 0
      },
      selected: [],
      reportStatus: ['RECEBIDO', 'EM PROCESSAMENTO', 'ENVIADO', 'ERRO'],
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Empresa', value: 'company_name'},
        {text: 'Mês', value: 'month'},
        {text: 'Ano', value: 'year'},
        {text: 'Recebido em', value: 'created_at'},
        {text: 'CNPJ', value: 'company_cnpj'},
        {text: 'Autor', value: 'type'},
        {text: 'Status', value: 'status'},
        {text: 'Ações', value: 'action'}
      ],
      reports: []
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadReports() {
      this.selected = [];
      this.loading = true;
      const {page, itemsPerPage} = this.options;
      const response = await getReports(page, itemsPerPage, this.status);
      if (response) {
        if (response.status === 200) {
          this.totalReports = response.data.total;
          this.reports = response.data.reports;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async confirmDeleteReport(item) {
      this.showConfirmation({
        title: CONFIRM,
        text: customDelete(item),
        action: async () => {
          this.loading = true;
          const response = await deleteReport(item);
          if (response) {
            if (response.status === 200) {
              await this.loadReports();
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.loading = false;
        }
      });
    },
    openChangeStatus(item) {
      this.statusChange = {
        id: item.id,
        status: item.status
      };
      this.dialogStatus = true;
    },
    async changeStatus() {
      if (this.statusChange.id) {
        this.loadingStatus = true;
        const response = await putReportStatus(this.statusChange.id, this.statusChange.status);
        if (response) {
          if (response.status === 200) {
            await this.loadReports();
            this.dialogStatus = false;
          } else {
            this.showAlert({message: response.message});
          }
        }
        this.loadingStatus = false;
      }
    },
    getDownloadLink(item) {
      return `${process.env.VUE_APP_DICI_BACKEND}/admin/report/${item.id}/download`;
    },
    validateReportsSendAnatel(reports) {
      let message = null;
      reports.map(r => {
        if (message) {
          return;
        }
        const occurrences = reports.filter(r1 => r1.month === r.month && r1.year === r.year && r1.company_cnpj === r.company_cnpj).length;
        if (occurrences !== 1) {
          message = `A empresa ${r.company_name} possui relatórios duplicados para a referência ${this.monthsKeyed[r.month]}/${r.year}`;
        }
      });
      return message;
    },
    async confirmSendAnatel() {
      if (this.selected.length) {
        const message = this.validateReportsSendAnatel(this.selected);
        if (!message) {
          const reports = this.selected.map(r => r.id);
          this.showConfirmation({
            title: CONFIRM,
            text: SEND_ANATEL,
            action: async () => {
              this.loading = true;
              const response = await sendReportsToAnatel(reports);
              if (response) {
                if (response.status === 200) {
                  this.userNameSend = response.data.user;
                  this.anatelSending = true;
                  await this.loadReports();
                } else {
                  this.showAlert({message: response.message});
                }
              }
              this.loading = false;
            }
          });
        } else {
          this.showAlert({message: message});
        }
      }
    },
    showInfo(item) {
      this.reportInfo = item;
      this.dialogInfo = true;
    }
  },
  watch: {
    options: {
      handler() {
        this.loadReports();
      },
      deep: true,
    },
  }
}
</script>
