import {request, dici} from '@/lib/backend/http';
import admin from '@/lib/backend/admin';

export const postUnsafeReportInfo = (token, version) => {
    const now = new Date();
    return request(dici().post, `/report/info`, {
        token: token,
        month: now.getMonth() + 1,
        year: now.getFullYear(),
        version: version,
        data: JSON.stringify({})
    }, false);
};

export const postUnsafeReport = (token, month, year, version, data) => {
    return request(dici().post, `/report`, {
        token: token,
        month: month,
        year: year,
        version: version,
        data: JSON.stringify(data)
    }, false);
};

export const getReports = (p, pp, s) => {
    return request(dici().get, admin(`/report?p=${p}&pp=${pp}&s=${s}`));
};

export const deleteReport = (report) => {
    return request(dici().delete, admin(`/report/${report.id}`));
};

export const sendReportsToAnatel = (reports) => {
    return request(dici().post, admin('/report/send-to-anatel'), {reports: reports});
};

export const putReportStatus = (id, status) => {
    return request(dici().put, admin(`/report/${id}/status`), {
        status: status
    });
};

