import {request, dici} from '@/lib/backend/http';
import admin from '@/lib/backend/admin';

export const getEmailTemplate = (name) => {
    return request(dici().get, admin(`/email/${name}`));
};

export const putEmailTemplate = (name, data) => {
    return request(dici().put, admin(`/email/${name}`), {data: JSON.stringify(data)});
};
