<template>
  <v-data-table
      class="elevation-4"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      :items-per-page="5"
      :headers="headers"
      :items="value"
  >
    <template v-slot:top>
      <div class="pa-1 subtitle-1 text-center">
        Relatório SCM
      </div>
    </template>
    <template v-slot:footer>
      <div class="pb-4 pt-3 text-center">
        <v-btn @click="addSCM" color="primary">
          <v-icon>mdi-plus</v-icon>
          Adicionar linha
        </v-btn>
      </div>
    </template>
    <template v-slot:item.c="{item}">
      <cities-selector :scm="item"/>
    </template>
    <template v-slot:item.ct="{item}">
      <v-select
          v-model="item.ct"
          :items="clientTypes"
          item-value="value"
          item-text="name"
      />
    </template>
    <template v-slot:item.at="{item}">
      <v-select
          v-model="item.at"
          :items="attendanceTypes"
          item-value="value"
          item-text="name"
      />
    </template>
    <template v-slot:item.et="{item}">
      <v-select
          v-model="item.et"
          :items="environmentTypes"
          item-value="value"
          item-text="name"
      />
    </template>
    <template v-slot:item.pt="{item}">
      <v-select
          v-model="item.pt"
          :items="productTypes"
          item-value="value"
          item-text="name"
      />
    </template>
    <template v-slot:item.tt="{item}">
      <v-select
          v-model="item.tt"
          :items="technologyTypes"
          item-value="value"
          :item-text="technologyDescription"
      />
    </template>
    <template v-slot:item.s="{item}">
      <v-text-field
          v-model.number="item.s"
          type="number"
          min="0"
          :rules="rulesSpeed"
      />
    </template>
    <template v-slot:item.a="{item}">
      <v-text-field
          outlined
          filled
          v-model.number="item.a"
          type="number"
          min="0"
          :rules="rulesAccessQuantity"
      />
    </template>
    <template v-slot:item.action="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" text v-bind="attrs" v-on="on">mdi-menu</v-icon>
        </template>
        <v-list>
          <v-list-item link @click="duplicate(item)">
            <v-icon class="mr-2">mdi-content-copy</v-icon>
            <v-list-item-title>Duplicar</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="!hasMultiRows" link @click="removeSCM(item)">
            <v-icon class="mr-2">mdi-delete</v-icon>
            <v-list-item-title>Remover</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import CitiesSelector from '@/components/CitiesSelector';
import {attendanceTypes, clientTypes, environmentTypes, productTypes, technologyTypes} from '@/lib/diciTypes';
import {rulesAccessQuantity, rulesSpeed} from '@/lib/validation';

export default {
  components: {CitiesSelector},
  props: {
    value: Array
  },
  name: 'SCMReporting',
  data() {
    return {
      rulesAccessQuantity: rulesAccessQuantity,
      rulesSpeed: rulesSpeed,
      technologyTypes: technologyTypes,
      productTypes: productTypes,
      environmentTypes: environmentTypes,
      attendanceTypes: attendanceTypes,
      clientTypes: clientTypes,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Município', value: 'c', width: '300'},
        {text: 'Tipo de cliente', value: 'ct'},
        {text: 'Tipo de atendimento', value: 'at'},
        {text: 'Tipo de meio', value: 'et'},
        {text: 'Tipo de produto', value: 'pt'},
        {text: 'Tipo de tecnologia', value: 'tt'},
        {text: 'Velocidade (Mbps)', value: 's'},
        {text: 'Quantidade de acessos', value: 'a'},
        {text: 'Ações', value: 'action', sortable: false}
      ]
    }
  },
  mounted() {
    if (!this.value.length) {
      this.addSCM();
    }
  },
  methods: {
    technologyDescription(t) {
      return `${t.value} - ${t.name}`
    },
    removeSCM(item) {
      if (this.hasMultiRows) {
        this.value.remove(item);
      }
    },
    addSCM() {
      this.value.push({
        id: this.value.getId(),
        c: 0,
        dc: null,
        ct: 'PF',
        at: 'URBANO',
        et: 'fibra',
        pt: 'internet',
        tt: 'FTTH',
        s: 100,
        a: 0
      })
    },
    duplicate(item) {
      this.value.push({
        id: this.value.getId(),
        c: item.c,
        dc: item.dc,
        ct: item.ct,
        at: item.at,
        et: item.et,
        pt: item.pt,
        tt: item.tt,
        s: item.s,
        a: item.a
      });
    }
  },
  computed: {
    hasMultiRows() {
      return this.value.length > 1;
    }
  }
}
</script>
