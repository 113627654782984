import {request, dici} from '@/lib/backend/http';
import admin from '@/lib/backend/admin';

export const postTemplate = (id) => {
    return request(dici().post, admin(`/company/${id}/template`));
};

export const putTemplate = (id, version, data) => {
    return request(dici().put, admin(`/template/${id}`), {
        version: version,
        data: JSON.stringify(data)
    });
};
