<template>
  <div class="fill-height" v-if="user.role === 'user'">
    <home-default title="Painel do usuário" :items="[account]"/>
  </div>
  <div class="fill-height" v-else-if="user.role === 'admin'">
    <home-default title="Painel do administrador" :items="[reports, companies, settings, routines, account]"/>
  </div>
  <div v-else class="display-1">O usuário não possui permissões para acessar telas</div>
</template>

<script>
import HomeDefault from '@/views/home/HomeDefault';
import {mapState} from 'vuex';
import {account, companies, reports, routines, settings} from '@/lib/menu';

export default {
  name: 'Home',
  components: {HomeDefault},
  data() {
    return {
      settings: settings(),
      account: account(),
      companies: companies(),
      reports: reports(),
      routines: routines()
    }
  },
  computed: {
    ...mapState({
      user: state => state.config.user
    })
  }
}
</script>
