<template>
  <div class="fill-height">
    <app-nav-bar title-bar="Rotinas" action-menu :back-route="goToHome"/>

    <v-dialog v-model="info" max-width="500">
      <v-card v-if="itemInfo">
        <v-card-title>{{ itemInfo.name }}</v-card-title>

        <v-card-text>
          {{ itemInfo.plus }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="info = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="fill-height">
      <v-container class="fill-height" fluid>
        <v-row justify="center">
          <v-col cols="12" lg="5" md="8">
            <v-card>
              <v-data-table
                  disable-sort
                  :headers="headers"
                  :items="routines"
                  :loading="loading"
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                  disable-filtering
              >
                <template v-slot:item.action="{ item }">
                  <v-icon class="mr-2" @click="execRoutine(item)">mdi-play</v-icon>
                  <v-icon @click="openInfo(item)">mdi-information</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToHome} from '@/lib/redirects';
import {mapActions} from 'vuex';
import {postRoutine} from '@/lib/backend/routine';

const ROUTINE_START_EMAIL_WARNING_SENDING = 1;
const ROUTINE_START_REPORT_AUTO_SENDING = 2;
const ROUTINE_START_SENDING_ANATEL = 3;
const CONFIRM = 'Executar rotina?';
const SUCCESS = 'Rotina iniciada';

export default {
  name: 'Routines',
  components: {AppNavBar},
  data() {
    return {
      itemInfo: null,
      info: false,
      loading: false,
      goToHome: goToHome,
      headers: [
        {text: '', value: 'name'},
        {text: '', value: 'description'},
        {text: '', value: 'action'}
      ],
      routines: [
        {
          id: ROUTINE_START_EMAIL_WARNING_SENDING,
          name: 'Email de aviso',
          description: 'Executar o serviço de aviso por e-mail',
          plus: `As empresas receberão uma mensagem informando a necessidade do preenchimento do relatório DICI.
          As empresas que já receberam o email de aviso no mês de referência serão ignoradas. Esta tarefa
          está programada automaticamente para o primeiro dia do mês atual.`
        },
        {
          id: ROUTINE_START_REPORT_AUTO_SENDING,
          name: 'Relatório automático',
          description: 'Executar o serviço de relatórios automáticos',
          plus: `O sistema buscará pelas empresas que não preencheram o relatório no mês anterior.
          Caso haja algum relatório preenchido em algum momento dentro do sistema, os valores serão repetidos. Esta tarefa
          está programada automaticamente para o décimo quinto dia do mês atual.`
        },
        {
          id: ROUTINE_START_SENDING_ANATEL,
          name: 'Envio para a Anatel',
          description: 'Executar o serviço de envio de relatórios para a Anatel',
          plus: `O sistema buscará pelos relatórios com status "EM PROCESSAMENTO" e realizará
          o envio para o sistema da Anatel. ATENÇÃO: ESTA ROTINA É PARA TESTES. Para envios normais utilize a página de relatórios.`
        }
      ]
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    execRoutine(item) {
      this.showConfirmation({
        title: CONFIRM,
        text: item.name,
        action: async () => {
          this.loading = true;
          const response = await postRoutine(item.id);
          if (response) {
            if (response.status === 200) {
              this.showAlert({message: SUCCESS, color: 'success', timeout: 1000});
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.loading = false;
        }
      });
    },
    openInfo(item) {
      this.itemInfo = item;
      this.info = true;
    }
  }
}
</script>
