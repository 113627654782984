<template>
  <div class="fill-height">
    <app-nav-bar title-bar="Relatório DICI Anatel"/>

    <loader :loading="loading">
      <v-main slot="content" class="fill-height">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col v-if="alert.show" cols="12" lg="4">
              <v-alert prominent :type="alert.type">
                <v-row align="center">
                  <v-col class="grow">
                    {{ alert.message }}
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col v-else cols="12">
              <v-card :loading="cardLoading" :disabled="cardLoading">
                <v-card-title>{{ companyName }}</v-card-title>

                <v-card-text>
                  <v-form ref="form">
                    <v-container class="pa-0" fluid>
                      <v-row>
                        <v-col cols="12">
                          <div>{{ companyCNPJ.cnpjMask() }}</div>
                          <div class="caption">VERSÃO DE DADOS: {{ version }}</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-select
                              v-model="month"
                              label="Mês"
                              :items="months"
                              item-text="name"
                              item-value="value"
                          />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-select
                              v-model="year"
                              label="Ano"
                              :items="years"
                              return-object
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <s-c-m-reporting-client v-model="data.scm"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer/>
                  <v-btn @click="sendReport" color="primary">
                    Enviar Relatório
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </loader>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {mapActions, mapState} from 'vuex';
import Loader from '@/components/Loader';
import {postUnsafeReportInfo, postUnsafeReport} from '@/lib/backend/report';
import {getEmptyData, months} from '@/lib/diciTypes';
import VERSION from '@/lib/version';
import {validateReportFields} from '@/lib/validation';
import SCMReportingClient from '@/views/reporting/SCMReportingClient';

const CONFIRM = 'Confirmar?';
const CONFIRM_SEND = 'As informações serão enviadas para a TK Engenharia e posteriormente enviadas para a Anatel. Não será possível editá-las novamente após o envio.';
const REPORT_SENT = 'Recebemos os seus dados com sucesso. Aguarde a confirmação de envio para a Anatel. Você pode fechar esta página.';
const NOT_ALLOWED_MESSAGE = 'Você não possui autorização para acessar esta página.';
const DISABLED_COMPANY = 'A empresa foi desabilitada para envios. Entre em contato com o administrador.';
const VERIFY_FIELDS = 'Todos os campos precisam ser válidos antes de serem enviados.';
const UNKNOWN_VERSION = v => `A versão de dados do modelo (${v}) é diferente da versão atual de dados (${VERSION}).`;

export default {
  name: 'Reporting',
  components: {SCMReportingClient, Loader, AppNavBar},
  data() {
    return {
      companyName: '',
      companyCNPJ: '',
      version: VERSION,
      month: 1,
      months: months,
      year: 1900,
      years: [],
      loading: true,
      cardLoading: false,
      alert: {
        show: false,
        message: '',
        type: 'info'
      },
      data: getEmptyData()
    }
  },
  computed: mapState({
    token: state => state.report.token
  }),
  mounted() {
    this.start();
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    sendReport() {
      if (this.$refs.form.validate()) {
        const message = validateReportFields(this.data);
        if (!message) {
          this.showConfirmation({
            title: CONFIRM,
            text: CONFIRM_SEND,
            action: async () => {
              this.cardLoading = true;
              const response = await postUnsafeReport(
                  this.token,
                  this.month,
                  this.year,
                  VERSION,
                  this.data
              );
              if (response) {
                if (response.status === 200) {
                  this.showAlertCard(REPORT_SENT);
                } else if (response.status === 422) {
                  this.showAlertCard(DISABLED_COMPANY, 'error');
                } else {
                  this.showAlert({message: response.message});
                }
              }
              this.cardLoading = false;
            }
          });
        } else {
          this.showAlert({message: message})
        }
      } else {
        this.showAlert({message: VERIFY_FIELDS})
      }
    },
    startReporting(data) {
      if (data.version === VERSION) {
        this.companyName = data.name;
        this.companyCNPJ = data.cnpj;
        const now = new Date();
        this.years = Array(4).fill('').map((v, idx) => now.getFullYear() - idx);
        now.setMonth(now.getMonth() - 1);
        this.year = now.getFullYear();
        this.month = now.getMonth() + 1;
        this.data = JSON.parse(data.template);
      } else {
        this.showAlertCard(UNKNOWN_VERSION(data.version), 'error');
      }
    },
    async start() {
      if (!this.token) {
        this.showAlertCard(NOT_ALLOWED_MESSAGE, 'error');
        this.loading = false;
        return;
      }
      const response = await postUnsafeReportInfo(this.token, VERSION);
      if (response) {
        if (response.status === 200) {
          this.startReporting(response.data);
        } else if (response.status === 401) {
          this.showAlertCard(NOT_ALLOWED_MESSAGE, 'error');
        } else if (response.status === 422) {
          this.showAlertCard(DISABLED_COMPANY, 'error');
        } else {
          this.showAlertCard(response.message, 'error');
        }
        this.loading = false;
      }
    },
    showAlertCard(message = '', type = 'info') {
      this.alert = {
        show: true,
        message: message,
        type: type
      }
    }
  }
}
</script>
