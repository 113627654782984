import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import Home from '@/views/Home';
import Logout from '@/views/Logout';
import Account from '@/views/Account';
import Companies from '@/views/Companies';
import Reports from '@/views/Reports';
import Reporting from '@/views/Reporting';
import Settings from '@/views/Settings';
import EmailWarningTemplate from '@/views/settings/EmailWarningTemplate';
import EmailConfirmationTemplate from '@/views/settings/EmailConfirmationTemplate';
import Routines from '@/views/Routines';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                roles: []
            }
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                roles: []
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                roles: []
            }
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            meta: {
                roles: []
            }
        },
        {
            path: '/companies',
            name: 'companies',
            component: Companies,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: Reports,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '/reporting',
            name: 'reporting',
            component: Reporting,
            meta: {
                roles: []
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '/routines',
            name: 'routines',
            component: Routines,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '/settings/email-warning-template',
            name: 'emailWarningTemplate',
            component: EmailWarningTemplate,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '/settings/email-confirmation-template',
            name: 'emailConfirmationTemplate',
            component: EmailConfirmationTemplate,
            meta: {
                roles: ['admin']
            }
        },
        {
            path: '*',
            redirect: '/',
            meta: {
                roles: []
            }
        }
    ]
})
