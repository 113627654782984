import {dici, request} from '@/lib/backend/http';

export const postLogin = (credentials) => {
    return request(dici().post, '/auth/login', credentials, false);
};

export const postLogout = () => {
    return request(dici().post, '/auth/logout', false);
};

export const postRegister = (user) => {
    return request(dici().post, '/auth/register', user, false);
};
