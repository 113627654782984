const state = {
    cities: [],
    uf: null,
    code: 0
};

const getters = {};

const actions = {
    setState({commit}, {cities, uf, code}) {
        commit('SET_STATE', {cities, uf, code});
    }
};

const mutations = {
    SET_STATE(state, {cities, uf, code}) {
        state.cities = cities;
        state.code = code;
        state.uf = uf;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
