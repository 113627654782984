<template>
  <div>
    <v-dialog v-model="dialogCities" persistent max-width="500">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <span class="headline">Selecionar município</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-select
                v-model="uf"
                :items="ufs"
                label="UF"
                :rules="[ruleNotEmpty]"
                @change="loadCities"
                item-text="name"
                item-value="value"
            />
            <v-autocomplete
                v-model="code"
                :filter="search"
                :items="cities"
                label="Município"
                :rules="[ruleNotEmpty]"
                item-text="name"
                item-value="code"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogCities = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="save">Concluído</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-text-field
        v-model="city"
        style="cursor: pointer"
        readonly
        :rules="[ruleNotEmpty]"
        @click="openDialog"
    />
  </div>
</template>

<script>
import {ufs} from '@/lib/diciTypes';
import {getCities} from '@/lib/backend/cities';
import {mapActions, mapState} from 'vuex';
import {latinize} from '@/lib/latinize';

const REQUIRED = 'Este campo é obrigatório';

export default {
  name: 'CitiesSelector',
  props: {
    scm: Object
  },
  data() {
    return {
      ruleNotEmpty: v => !!v || REQUIRED,
      ufs: ufs,
      cities: [],
      loading: false,
      dialogCities: false,
      uf: null,
      city: '',
      code: 0
    }
  },
  mounted() {
    if (this.scm.dc && this.scm.c) {
      this.city = this.scm.dc;
    }
  },
  methods: {
    ...mapActions('cities', ['setState']),
    ...mapActions('alert', ['showAlert']),
    openDialog() {
      this.cities = this.citiesState;
      this.uf = this.ufState;
      this.code = this.codeState;
      this.dialogCities = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        const cityObjs = this.cities.filter(c => c.code === this.code);
        if (cityObjs.length) {
          const cityObj = cityObjs[0]
          this.scm.c = this.code;
          const description = `${cityObj.name} - ${cityObj.uf}`;
          this.city = description;
          this.scm.dc = description;
          this.dialogCities = false;
          this.setState({cities: this.cities, uf: this.uf, code: this.code})
        }
      }
    },
    async loadCities() {
      this.loading = true;
      const response = await getCities(this.uf);
      if (response) {
        if (response.status === 200) {
          this.code = 0;
          this.cities = response.data;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    search(item, queryText) {
      const query = latinize(queryText).replace(/\s/g, '').toLowerCase();
      return item.search.includes(query);
    }
  },
  computed: {
    ...mapState({
      citiesState: state => state.cities.cities,
      ufState: state => state.cities.uf,
      codeState: state => state.cities.code
    })
  }
}
</script>
