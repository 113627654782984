var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","items-per-page":5,"headers":_vm.headers,"items":_vm.value},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-1 subtitle-1 text-center"},[_vm._v(" Relatório SCM ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"pb-4 pt-3 text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addSCM}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Adicionar linha ")],1)],1)]},proxy:true},{key:"item.c",fn:function(ref){
var item = ref.item;
return [_c('cities-selector',{attrs:{"scm":item}})]}},{key:"item.ct",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.clientTypes,"item-value":"value","item-text":"name"},model:{value:(item.ct),callback:function ($$v) {_vm.$set(item, "ct", $$v)},expression:"item.ct"}})]}},{key:"item.at",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.attendanceTypes,"item-value":"value","item-text":"name"},model:{value:(item.at),callback:function ($$v) {_vm.$set(item, "at", $$v)},expression:"item.at"}})]}},{key:"item.et",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.environmentTypes,"item-value":"value","item-text":"name"},model:{value:(item.et),callback:function ($$v) {_vm.$set(item, "et", $$v)},expression:"item.et"}})]}},{key:"item.pt",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.productTypes,"item-value":"value","item-text":"name"},model:{value:(item.pt),callback:function ($$v) {_vm.$set(item, "pt", $$v)},expression:"item.pt"}})]}},{key:"item.tt",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.technologyTypes,"item-value":"value","item-text":_vm.technologyDescription},model:{value:(item.tt),callback:function ($$v) {_vm.$set(item, "tt", $$v)},expression:"item.tt"}})]}},{key:"item.s",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","min":"0","rules":_vm.rulesSpeed},model:{value:(item.s),callback:function ($$v) {_vm.$set(item, "s", _vm._n($$v))},expression:"item.s"}})]}},{key:"item.a",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","filled":"","type":"number","min":"0","rules":_vm.rulesAccessQuantity},model:{value:(item.a),callback:function ($$v) {_vm.$set(item, "a", _vm._n($$v))},expression:"item.a"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.duplicate(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-copy")]),_c('v-list-item-title',[_vm._v("Duplicar")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.hasMultiRows,"link":""},on:{"click":function($event){return _vm.removeSCM(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Remover")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }